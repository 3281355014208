import React, { useEffect, useState } from 'react';

import '../style/page.scss';
import ReviewTable from '../components/ReviewTable/ReviewTable';
import Page from '../components/page';
import { useBoards, useReviews, useOrganizations, generateQuery } from '../adapters';
import { serializeData } from '../utils';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapperBody: {
    backgroundColor: theme.palette.common.white,
  },
}));

const ReviewsPage = () => {
  const classes = useStyles();

  const { getBoards } = useBoards();
  const { getOrganizations } = useOrganizations();
  const { isFetchingReviews, getReviews } = useReviews();
  const [boards, setBoards] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [reviews, setReviews] = useState(null);
  const [count, setCount] = useState(0);

  const fetchReviews = async (query: string) => {
    setReviews(null);
    const res = await getReviews(query);
    if (res && res.data) {
      setCount(res.data.count);
      setReviews(await serializeData(res.data.reviews));
    }
  };

  useEffect(() => {
    let mounted = true;
    (async () => {
      // fetch boards
      const res = await getBoards({
        getActiveReviewCounts: false,
        getImages: false,
      });
      if (mounted && res && res.data) {
        const arr = [{ abbr: 'All', label: 'All' }];
        res.data.forEach((board) => {
          arr.push({
            abbr: board.abbreviation.toLowerCase(),
            label: board.abbreviation,
          });
        });
        setBoards(arr);
      }
    })();

    (async () => {
      // fetch organizations
      const orgs = await getOrganizations();
      if (mounted && orgs && orgs.data) {
        setOrganizations(orgs.data);
      }
    })();

    // fetch reviews
    (async () => {
      // fetch reviews from the beginning
      await fetchReviews(generateQuery());
    })();

    return () => (mounted = false);
  }, []);

  return (
    <Page isFetching={false} wrapperBody={classes.wrapperBody}>
      <ReviewTable
        isFetchingReviews={isFetchingReviews}
        organizations={organizations}
        boards={boards}
        reviews={reviews}
        totalReviewsCount={count}
        onUpdate={async (query) => await fetchReviews(query)}
      />
    </Page>
  );
};

export default ReviewsPage;
